import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';

const Home = (props) => {
  const markdown = props.data.allMarkdownRemark.edges;
  const newsEvents = (props.data.allNewsEventsJson && props.data.allNewsEventsJson.edges) || [];
  return (
    <Layout bodyClass="page-home">
      <SEO title="Home" />
      <Helmet>
        <meta name="description" content="West Potsdam Volunteer Fire Department, Potsdam NY" />
      </Helmet>
      <div className="intro pb-2">
        <div className="container">
          <h1>West Potsdam Fire Department</h1>
          <p>Serving West Potsdam since 1962</p>
        </div>
      </div>

      {newsEvents.length > 0 && (
        <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
          <div className="row justify-content-center">
            <div className="col-12">
              <h2 className="title-3 text-dark mb-4">News and Events</h2>
            </div>
            {newsEvents.map(edge => (
              <div key={edge.node.id} className="col-12 col-md-6 col-lg-4 mb-2">
                <div className="feature">
                  {edge.node.image && (
                    <div className="feature-image">
                      <img src={withPrefix(edge.node.image)} />
                    </div>
                  )}
                  <h2 className="feature-title">{edge.node.title}</h2>
                  <div className="feature-content">{edge.node.description}</div>
                  {edge.node.more_info_url && <a href={edge.node.more_info_url}> ... more</a>}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {false && (
        <div className="container pt-1 pt-md-1">
          <div className="row justify-content-start">
            <div className="col-12">
              <h2 className="title-3 text-dark mb-3">Community Service</h2>
            </div>
            {markdown.map(edge => (
              <div key={edge.node.frontmatter.path} className="col-12 col-md-4 mb-1">
                <div className="card service service-teaser">
                  <div className="card-content">
                    <h2>
                      <Link to={edge.node.frontmatter.path}>{edge.node.frontmatter.title}</Link>
                    </h2>
                    <p>{edge.node.excerpt}</p>
                  </div>
                </div>
              </div>
            ))}
            <div className="col-12 text-center">
              <Link className="button button-primary mt-2" to="/community-service">
                View All Services
              </Link>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/community-service/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
    allNewsEventsJson(filter: { draft: { eq: false } }) {
      edges {
        node {
          id
          title
          description
          image
          more_info_url
          draft
        }
      }
    }
  }
`;

export default Home;
